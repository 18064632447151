<template>
  <div class="combi-products-homepage larger-container larger-container-symetrical fold-bg-after-white">
    <h2 class="background-heading static smaller font-brown">
      Dárkové sety
    </h2>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <p class="main-paragraph">
            Udělejte radost někomu blízkému a darujte dárkový set s pěti libovolnými ginger shoty.
          </p>
          <div class="combi-products-multiple-wrapper">
            <CombiProductPreview :shotPhotos="shotPhotos" :class="shotsOldClass" />
            <CombiProductPreview :shotPhotos="shotPhotosNew" :class="`shots-overlay ${shotsNewClass}`" />
          </div>
          <div class="buy-button-wrapper">
            <router-link :to="{ name: 'ProductCombi' }">
              <Button color="black">
                Koupit
              </Button>
            </router-link>
          </div>
        </div>
        <div class="col-lg-6">
          <CombiProductGraphics :image="product.image" :opacity="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/views/components/Button.vue';
import CombiProductPreview from '@/views/components/CombiProductPreview.vue';
import CombiProductGraphics from '@/views/components/CombiProductGraphics.vue';
import { getProductOptions } from '@/views/pages/CombiProduct.vue';

const shotCount = 5;
const swapInterval = 2;

export default {
  name: 'CombiProducts',
  components: {
    CombiProductGraphics,
    CombiProductPreview,
    Button,
  },
  data() {
    return {
      shotPhotos: Array.apply(null, Array(shotCount)),
      shotPhotosNew: [],
      randomInterval: null,
      shotsOldClass: 'no-transparent',
      shotsNewClass: 'transparent'
    };
  },
  computed: {
    product() {
      let products = this.$api.combiProducts;

      // Products not loaded yet
      if (!Object.keys(products).length)
        return null;

      return products[0];
    },
    photoOptions() {
      const realShots = getProductOptions(this.$api.products)
          .map(({ photo }) => photo);
      return [
          // Make it twice as likely to get a real shot than an unknown
        ...realShots,
        ...realShots,
        null,
      ];
    },
  },
  methods: {
    getRandomShot() {
      const options = this.photoOptions;
      return options[Math.floor(Math.random()*options.length)];
    },
    async randomizeOneShot() {
      this.shotPhotos = [...this.shotPhotosNew];

      // Wait for images to load
      await this.timeout(100);

      this.shotsNewClass = 'transparent'
      this.shotsOldClass = 'no-transparent';

      const index = Math.floor(Math.random()*shotCount);

      // Randomly change one shot
      while (
          JSON.stringify(this.shotPhotos) === JSON.stringify(this.shotPhotosNew) ||  // force a change to occur
          this.getMostFrequentElement(this.shotPhotosNew) > 2 // every shot at most twice
        ) {
        this.$set(this.shotPhotosNew, index, this.getRandomShot());
      }

      // Wait for images to load
      await this.timeout(100);

      this.shotsNewClass = 'transition-show no-transparent';
      this.shotsOldClass = 'transition-hide transparent';

      this.randomInterval = setTimeout(() => this.randomizeOneShot(), swapInterval * 1000);
    },
    getMostFrequentElement(arr) {
      const frequencyMap = {};

      arr.forEach((item) => {
        frequencyMap[item] = (frequencyMap[item] || 0) + 1;
      });

      return Math.max(...Object.values(frequencyMap));
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  created() {
    this.shotPhotos = this.shotPhotos.map((_, index) => this.photoOptions[index]);
    this.shotPhotosNew = [...this.shotPhotos];
    this.randomInterval = setTimeout(() => this.randomizeOneShot(), swapInterval * 1000);
  },
  destroyed() {
    clearInterval(this.randomInterval);
  }
};
</script>
